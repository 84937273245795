import React from 'react';
// import { Helmet } from 'react-helmet';
// import './sponsorship.scss';
import Layout from '../components/Layout/Layout';
import Form from '../components/Form/Form';

const Contact = () => {
  return (
    <Layout>
    <Form />
    </Layout>
  );
};

export default Contact;
